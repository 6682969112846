<template>
    <section class="tables">
      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="card-title">
                Modifier la magasin  
                <router-link to="/getmagasins">
                  <span class="float-right btn btn-info"> retour </span>
                </router-link>
              </div>
              <div class="container">
                <form @submit.prevent="updatemagasin()">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >Nom <span class="obligatoire">*</span>
                        </label>
  
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          v-model=magasin.nom

                          id="first"
                          required
                        />
                      </div>
                    </div>
                    <!--  col-md-6   -->
  
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >Adresse <span class="obligatoire">*</span>
                        </label>
  
                        <input
                          type="text"
                          class="form-control"
                          placeholder=""
                          id="first"
                          v-model=magasin.adresse

                          required
                        />
                      </div>
                    </div>
                    
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >categorie <span class="obligatoire">*</span>
                        </label>
  
                        <input
                          type="text"
                          class="form-control"
                          v-model=magasin.categorie
                          placeholder=""
                          id="first"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label for="first"
                          >capacite <span class="obligatoire">*</span>
                        </label>
  
                        <input
                          type="number"
                          class="form-control"
                          v-model=magasin.capacite
                          placeholder=""
                          id="first"
                          :min="1"
                          required
                        />
                      </div>
                    </div>
                    <div class="col-md-4">
                    <div class="form-group">
                      <label for="first"
                        >Emplacements
                        <span class="obligatoire">*</span>
                      </label>

                      <v-select
                        :multiple="true"
                        :closeOnClick="false"
                        v-model="test"
                        :options="emplacements"
                        :reduce="(emplacements) => emplacements"
                        label="code"
                      />
                    <span class="text-danger" id="emplac_valid" style="display:none">Il faut choisir les emplacements</span>

                    </div>
                  </div>
                  <div class="col-md-4">
                    <br>
                    <div class="form-group">
                  <button type="submit" class="btn btn-primary">
                    + Modifier magasin
                  </button>
                  </div>
                  </div>
  
                    <!--  col-md-6   -->
                  </div>
                 
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  import { HTTP } from '@/axios';
  import Swal from "sweetalert2";
  
  import moment from "moment";
  export default {
    data() {
      return {
        test:[],
        magasin:{
        },
     emplacements:[],
        users: [],
        error: "",
      };
    },
  
    created() {
        this.getemplacements()
        this.getonemagasin()
    },
    methods: {
        getonemagasin() {
      HTTP
        .get("magasins/getmagasinbyid/" + this.$route.params.id)
        .then((response) => {
          this.magasin = response.data;
          this.magasin.emplacements.forEach((emplac) => {
        delete emplac.emplacement_id.__v
        delete emplac.emplacement_id.quantite_existante
        delete emplac.emplacement_id.capacite_vide
        delete emplac.emplacement_id.created_at
        delete emplac.emplacement_id.updated_at

   



            this.test.push(emplac.emplacement_id)
        });
        })
        .then((err) => console.log(err));
    },


        getemplacements() {
      HTTP.get("emplacements/getemplacementsfields")
        .then((response) => {
          this.emplacements = response.data;

        })
        .then((err) => console.log(err));
    },

      updatemagasin() {


        
    if(this.test.length==0){
      $('#emplac_valid').show()

setTimeout(function() {
     $('#emplac_valid').fadeOut('fast');
}, 5000); 


    }

    else if(this.test.length>0){
        this.magasin.emplacements=this.test

        this.magasin.emplacements.forEach((emplac) => {
       emplac.emplacement_id=emplac._id
        delete emplac.code
        delete emplac.capacite
        });

        HTTP.put("magasins/updmagasin", this.magasin).then(
        (response) => {
          console.log(response);
          Toast.fire({
            icon: "success",
            title: "Magasin modifié",
          });

          this.$router.push("/getmagasins");
        },
        (err) => {
          console.log(err);
        }
      );    


      }









  
      }
    },
  };
  </script>
  